import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const SSE_HOST = new InjectionToken<string>('sse host');
export const APP_VERSION = new InjectionToken<string>('current app version');

export const IS_ADMIN = new InjectionToken<boolean>('is admin');

export const IS_READ_ADMIN = new InjectionToken<Observable<boolean>>(
  'is read admin',
);

export const IS_DEV_SUPPORT = new InjectionToken<Observable<boolean>>(
  'is dev support',
);

export const INTERNAL_BETA = new InjectionToken<Observable<boolean>>(
  'internal beta',
);

export const EXTENDED_BETA = new InjectionToken<Observable<boolean>>(
  'extended beta',
);

export const PROD = new InjectionToken<boolean>('is production');
